<template>
	<div class="main-content">
		<el-tabs v-model="activeName">
			<el-tab-pane label="折扣配置" name="second">
				<el-table :data="disList" border style="width: 520px">
					<el-table-column prop="name" label="等级"></el-table-column>
					<el-table-column prop="a" label="A类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ discountConfig[0][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'discountConfig',type: 0})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="b" label="B类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ discountConfig[1][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'discountConfig',type: 1})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="c" label="C类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ discountConfig[2][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'discountConfig',type: 2})"></el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<p style="font-size: 14px; color: #D9001B">注：比例值设置为1时，该商品该商品不打折扣，设置为0时，0元购买</p>
			</el-tab-pane>

			<el-tab-pane label="伯乐奖励配置" name="third">
				<el-table :data="boleList" border style="width: 520px">
					<el-table-column prop="name" label="等级"></el-table-column>
					<el-table-column prop="a" label="A类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ boleAwardConfig[0][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'boleAwardConfig',type: 0})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="b" label="B类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ boleAwardConfig[1][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'boleAwardConfig',type: 1})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="c" label="C类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ boleAwardConfig[2][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'boleAwardConfig',type: 2})"></el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<p style="font-size: 14px; color: #D9001B">注：设置为0时，不享受伯乐奖励</p>
			</el-tab-pane>

			<el-tab-pane label="平推奖励配置" name="four">
				<el-table :data="recList" border style="width: 520px">
					<el-table-column prop="name" label="等级"></el-table-column>
					<el-table-column prop="a" label="A类商品">
					<template slot-scope="scope">
						<div class="flex" style="justify-content: space-between;">
							<span>{{ recommendAwardConfig[0][scope.row.key] }}</span>
							<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'recommendAwardConfig',type: 0})"></el-button>
						</div>
					</template>
				</el-table-column>
					<el-table-column prop="b" label="B类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ recommendAwardConfig[1][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'recommendAwardConfig',type: 1})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="c" label="C类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ recommendAwardConfig[2][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'recommendAwardConfig',type: 2})"></el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<p style="font-size: 14px; color: #D9001B">注：设置为0时，不享受平推奖励</p>
				<p style="font-size: 14px;">注：当关系链上存多个横推上级时，只有最近的一个横推上级拿到奖励，其余横推不拿平推奖励</p>
			</el-tab-pane>

			<el-tab-pane label="自选B礼包最低门槛" name="fire">
				<p>配置最低的消费门槛（元）</p>
				<el-table :data="[1]" border style="width: 520px" :show-header="false">
					<el-table-column label="自选B礼包最低门槛">
						<template slot-scope="scope"> 自选B礼包最低门槛 </template>
					</el-table-column>
					<el-table-column prop="s" label="元">
						<template slot-scope="scope"> {{ giftBLowerThreshold }} </template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" class="pointers" @click="editLevelVal(scope, {name: 'giftBLowerThreshold', type: 0})">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>

			<el-tab-pane label="解冻保证金比例" name="six">
				<el-table :data="securityList" border style="width: 520px">
					<el-table-column prop="name" label="等级"></el-table-column>
					<el-table-column prop="a" label="A类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ unfrozenDepositRateConfig[0][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'unfrozenDepositRateConfig',type: 0})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="b" label="B类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ unfrozenDepositRateConfig[1][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'unfrozenDepositRateConfig',type: 1})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="c" label="C类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ unfrozenDepositRateConfig[2][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'unfrozenDepositRateConfig',type: 2})"></el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<p style="font-size: 14px; color: #D9001B">注：比例值设置为1时，按商品销售价进行解冻，设置为0时，不参与解冻</p>
			</el-tab-pane>

			<el-tab-pane label="解冻库存金比例" name="seven">
				<el-table :data="stockList" border style="width: 520px">
					<el-table-column prop="name" label="等级"></el-table-column>
					<el-table-column prop="a" label="A类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ unfrozenStockBalanceRateConfig[0][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'unfrozenStockBalanceRateConfig',type: 0})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="b" label="B类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ unfrozenStockBalanceRateConfig[1][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'unfrozenStockBalanceRateConfig',type: 1})"></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="c" label="C类商品">
						<template slot-scope="scope">
							<div class="flex" style="justify-content: space-between;">
								<span>{{ unfrozenStockBalanceRateConfig[2][scope.row.key] }}</span>
								<el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editLevelVal(scope, {name: 'unfrozenStockBalanceRateConfig',type: 2})"></el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<p style="font-size: 14px; color: #D9001B">注：比例值设置为1时，按商品销售价进行解冻，设置为0时，不参与解冻</p>
			</el-tab-pane>
		</el-tabs>

		<!-- 修改数值 -->
		<el-dialog class="edit-grade" :title="editValTitle" :visible.sync="editValFlag" width="600px">
			<el-form label-width="100px">
				<el-form-item label="当前值：">
					{{ currentNum }}
				</el-form-item>
				<el-form-item v-if="editKey" label="修改值：" required>
					<el-input v-model="editNum" :onkeyup="editNum = publicFormatNum(editNum, 2)" style="width:80px;"></el-input>
				</el-form-item>
				<el-form-item v-else label="修改值：" required>
					<el-input v-model="editNum" :onkeyup="editNum = publicFormatNum(editNum, 1)" style="width:180px;"></el-input>
				</el-form-item>
				<div style="text-align:right;">
					<el-button @click="editValFlag=false">取消</el-button>
					<el-button type="primary" @click="saveSubmit">确认</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {getAwardConfigSetting, saveAwardConfigSetting} from '@/api/setting';

	export default {
		name: 'rewardSet',
		data() {
			return {
				activeName: 'second',
				levelLoading: false,
				discountConfig: [
					{s: 0.57,	a:0.6,	b:0.7,  c: 0.85, z: 1, ledgerAccountType: 1},
					{s: 0.7,	a:0.75,	b:0.8,	c: 0.9,  z: 1, ledgerAccountType: 2},
					{s: 1,		a:1,	b:1,	c: 1,    z: 1, ledgerAccountType: 3},
				],
				boleAwardConfig: [
					{s: 0.01, ledgerAccountType: 1},
					{s: 0.01, ledgerAccountType: 2},
					{s: 0.01, ledgerAccountType: 3},
				],
				recommendAwardConfig: [
					{s: 0.01, a:0.06, b:0.06, ledgerAccountType: 1},
					{s: 0.01, a:0.06, b:0.06, ledgerAccountType: 2},
					{s: 0.01, a:0.06, b:0.06, ledgerAccountType: 3},
				],
				unfrozenDepositRateConfig: [
					{s: 0.57,	a:0.6,	ledgerAccountType: 1},
					{s: 0.7,	a:0.75,	ledgerAccountType: 2},
					{s: 1,		a:1,    ledgerAccountType: 3},
				],
				unfrozenStockBalanceRateConfig: [
					{s: 0.57,	a:0.6,	b:0.7,  ledgerAccountType: 1},
					{s: 0.7,	a:0.75,	b:0.8,	ledgerAccountType: 2},
					{s: 1,		a:1,	b:1,	ledgerAccountType: 3},
				],
				giftBLowerThreshold: 0,
				disList: [
					{name: '1级', key: 's'},
					{name: '2级', key: 'a'},
					{name: '3级', key: 'b'},
					{name: '4级', key: 'c'},
					{name: '注册用户', key: 'z'},
				],
				boleList: [
					{name: '1级', key: 's'},
				],
				recList: [
					{name: '1级', key: 's'},
					{name: '2级', key: 'a'},
					{name: '3级', key: 'b'},
				],
				securityList: [
					{name: '1级', key: 's'},
					{name: '2级', key: 'a'},
				],
				stockList: [
					{name: '1级', key: 's'},
					{name: '2级', key: 'a'},
					{name: '3级', key: 'b'},
				],
				editValTitle: '编辑',
				editValFlag: false,
				editName: '',
				editType: 0,
				editKey: '',
				currentNum: 0,
				editNum: 0,
			};
		},
		mounted() {
			this.getAllData();
		},
		methods: {
			async getAllData() {
				let { data } = await getAwardConfigSetting();
				if(data) {
					let discountConfig = data.discountConfig ? JSON.parse(data.discountConfig) : null;
					let boleAwardConfig = data.boleAwardConfig ? JSON.parse(data.boleAwardConfig) : null;
					let recommendAwardConfig = data.recommendAwardConfig ? JSON.parse(data.recommendAwardConfig) : null;
					let unfrozenDepositRateConfig = data.unfrozenDepositRateConfig ? JSON.parse(data.unfrozenDepositRateConfig) : null;
					let unfrozenStockBalanceRateConfig = data.unfrozenStockBalanceRateConfig ? JSON.parse(data.unfrozenStockBalanceRateConfig) : null;
					if(discountConfig instanceof Array) {
						this.discountConfig = discountConfig;
					}
					if(boleAwardConfig instanceof Array) {
						this.boleAwardConfig = boleAwardConfig;
					}
					if(recommendAwardConfig instanceof Array) {
						this.recommendAwardConfig = recommendAwardConfig;
					}
					if(unfrozenDepositRateConfig instanceof Array) {
						this.unfrozenDepositRateConfig = unfrozenDepositRateConfig;
					}
					if(unfrozenStockBalanceRateConfig instanceof Array) {
						this.unfrozenStockBalanceRateConfig = unfrozenStockBalanceRateConfig;
					}
					this.giftBLowerThreshold = data.giftBLowerThreshold || 0;
				}
			},
			//编辑等级需要的活跃值
			editLevelVal({row}, {name, type}) {
				this.editName = name;
				this.editType = type;
				this.editKey = name === 'giftBLowerThreshold' ? 0 : row.key;
				let goodsName = ['A类商品', 'B类商品', 'C类商品'];
				switch (name) {
					case 'discountConfig':
						this.editValTitle = '折扣配置' + row.name + '-' + goodsName[type];
						this.currentNum = this[name][type][row.key];
						break;
					case 'boleAwardConfig':
						this.editValTitle = '伯乐奖励配置' + row.name + '-' + goodsName[type];
						this.currentNum = this[name][type][row.key];
						break;
					case 'recommendAwardConfig':
						this.editValTitle = '平推奖励配置-' + row.name + '-' + goodsName[type];
						this.currentNum = this[name][type][row.key];
						break;
					case 'giftBLowerThreshold':
						this.editValTitle = '自选B礼包最低门槛';
						this.currentNum = this.giftBLowerThreshold;
						break;
					case 'unfrozenDepositRateConfig':
						this.editValTitle = '折扣配置' + row.name + '-' + goodsName[type];
						this.currentNum = this[name][type][row.key];

						break;
					case 'unfrozenStockBalanceRateConfig':
						this.editValTitle = '折扣配置' + row.name + '-' + goodsName[type];
						this.currentNum = this[name][type][row.key];
						break;
				}
				this.editNum = this.currentNum || 0;
				this.editValFlag = true;
			},
			async saveSubmit() {
				let params = {
					discountConfig: this.discountConfig,
					boleAwardConfig: this.boleAwardConfig,
					recommendAwardConfig: this.recommendAwardConfig,
					giftBLowerThreshold: this.giftBLowerThreshold,
					unfrozenDepositRateConfig: this.unfrozenDepositRateConfig,
					unfrozenStockBalanceRateConfig: this.unfrozenStockBalanceRateConfig,
				};
				if(this.editName === 'giftBLowerThreshold') {
					params.giftBLowerThreshold = this.editNum;
				} else {
					params[this.editName][this.editType][this.editKey] = this.editNum;
				}
				this.editValFlag = false;
				let { success, alertMsg } = await saveAwardConfigSetting({
					discountConfig: JSON.stringify(params.discountConfig),
					boleAwardConfig: JSON.stringify(params.boleAwardConfig),
					recommendAwardConfig: JSON.stringify(params.recommendAwardConfig),
					giftBLowerThreshold: params.giftBLowerThreshold,
					unfrozenDepositRateConfig: JSON.stringify(params.unfrozenDepositRateConfig),
					unfrozenStockBalanceRateConfig: JSON.stringify(params.unfrozenStockBalanceRateConfig),
				});
				if(success) {
					this.$message({type: 'success', message: '修改成功！'});
					this.getAllData();
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误！'});
				}
			},
		}
	};
</script>
<style lang="scss">
	.table-box {
		width: 400px;
		padding: 20px;
		.title {
			font-weight: bold;
		}
		.gray {
			background: #f2f2f2;
		}
		.item {
			display: flex;
			line-height: 40px;
			height: 40px;
			font-size: 16px;
			span {
				flex: 1;
				padding-left: 20px;
				border: 2px #f2f2f2 solid;
			}
		}
	}
</style>